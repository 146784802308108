import React from 'react'
import "./experience.css"
import { Swiper, SwiperSlide } from "swiper/react"
import { EffectCards } from "swiper"
import "swiper/css"
import "swiper/css/effect-cards"

import INTEL from '../../assets/icon-intel.svg'
import AONIC from '../../assets/icon-aonic.svg'

const data = [
  {
    image: INTEL,
    duration: "April 2017 - November 2018",
    description: "Failure analysis engineer for Intel's CPU products. Wrote Python automation scripts for the team."
  },
  {
    image: AONIC,
    duration: "March 2019 - Present",
    description: "Working on highly performant computer vision solutions for the company's tree detection software, using Python and C++."
  }
];

const Experience = () => {
  return (
    <section id="experience">
      <h2>Past Experiences</h2>

      <div className="work__container">
          <Swiper
            effect={"cards"}
            grabCursor={true}
            modules={[EffectCards]}
            className="mySwiper"
            >

            {
              data.map(({image, duration, description}, index) => {
                return (
                  <SwiperSlide key={index} >
                  <div className="slider__container">
                    <div className="image__container">
                        <img src={image} className="image" alt="" />
                    </div>
                    <div className="description">
                        <div className="work-duration">{duration}</div>
                        <div className="work-description">{description}</div>
                    </div>
                   </div>
                  </SwiperSlide>
                )
              })
            }
          </Swiper>
      </div>

    </section>
  )
}

export default Experience