import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/pumpkin_lord.svg'
import HeaderSocials from './HeaderSocials'

const Header = () => {
  return (
    <header id="header">
      <div className="container header__container">
        <h1>Brian Sia</h1>
         <h5 className="text-light">
           Software Engineer
         </h5>
         <CTA />
         <div className="me">
           <img src={ME} className="me-image" alt="me" />
         </div>

      </div>
    </header>
  )
}

export default Header