import React, { useRef, useState } from 'react'
import {MdOutlineEmail} from 'react-icons/md'
import emailjs from 'emailjs-com';
import './contact.css'
import Popup from './Popup';

const Contact = () => {

  const [buttonPopup, setButtonPopup] = useState(false);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_blsqqel', 'template_4jdf54r', form.current, 'PM5hnYu_F5rD56x6b')
  };

  return (
    <section id="contact">
        <h2>Contact Me</h2>

        <div className="container contact__container">
          <div className="contact__options">
              <article className="contact__option">
                <MdOutlineEmail className="contact__option-icon"/>
                <h4>Email</h4>
                <h5>briansia93@gmail.com</h5>
                <a href="mailto:briansia93@gmail.com" target="_blank" rel="noreferrer">Send a Message</a>
              </article>
          </div>
          {/* END OF CONTACT OPTIONS */}
          <form ref={form} name="submit-form" onSubmit={sendEmail}>
            <input type="text" name='name' placeholder='Your Full Name' required/>
            <input type="email" name='email' placeholder='Your Email'/>
            <textarea name="message" rows="7" placeholder='Your Message' required></textarea>
            <div>
                <button type="submit" className='btn btn-primary' onClick={() => {
                  let name = document.forms['submit-form']['name'].value;
                  let email = document.forms['submit-form']['email'].value;
                  let message = document.forms['submit-form']['message'].value;
                  if(name.length > 0 && email.length > 0 && message.length > 0){
                        console.log(typeof document.forms['submit-form']['name'].value);
                        console.log(typeof document.forms['submit-form']['email'].value);
                        console.log(typeof document.forms['submit-form']['message'].value);
                        setButtonPopup(true)
                  }
                }}>Send Message</button>
                <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
                    <h3 style={{"color": "black", "text-align": "center"}}>Message sent.</h3>
                </Popup>
            </div>
          </form>
        </div>
    </section>
  )
}

export default Contact
