import React from 'react'
import './portfolio.css'
import PATHFINDER_IMG from '../../assets/pathfinder.jpg'
import PERSONAL_BLOG from '../../assets/personal_blog.png'
import CLI_KANBAN from "../../assets/cli_kanban.png"

const data = [
  {
    id: 3,
    image: CLI_KANBAN,
    title: 'CLI Kanban',
    github: 'https://github.com/zerovirus123/cli_kanban',
    demo: ''
  },
  {
    id: 2,
    image: PATHFINDER_IMG,
    title: 'Pathfinder',
    github: 'https://github.com/zerovirus123/pathfinder',
    demo: 'https://zerovirus123.github.io/pathfinder/'
  },
  {
    id: 1,
    image: PERSONAL_BLOG,
    title: 'Personal Blog',
    demo: 'http://www.briansia.com'
  }
]

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {
          data.map(({id, image, title, github, demo}) => {
            return (
              <article key={id} className='portfolio__item'>
                  <div className="portfolio__item-image">
                      <img src={image} alt={title} className='portfolio__image' />
                  </div>
                  <h3> {title} </h3>
                  <div className="portfolio__item-cta">
                      <a href={github} className='btn' target='_blank' rel="noreferrer">Github</a>
                        {demo !== '' && 
                            <a href={demo} className='btn btn-primary' target='_blank' rel="noreferrer">Live Demo</a>
                        }
                  </div>     
              </article>
            )
          })
        }
        
      </div>
    </section>
  )
}

export default Portfolio