import React from 'react'
import './skillsets.css'
import {BsPatchCheckFill} from 'react-icons/bs'


const Skillsets = () => {
  return (

    <section id="skillsets">
       <h2>Skillsets</h2>

       <div className="container skillsets__container">
        <div className="Languages">
           <h3>Languages/Frameworks</h3>
           <div className="skillsets__content">
           <article className='skillsets__details'>
              <BsPatchCheckFill className="skillsets__details-icon" />
              <div>
                <h4>Python</h4>
              </div>
            </article>
            <article className='skillsets__details'>
              <BsPatchCheckFill className="skillsets__details-icon" />
              <div>
                <h4>C++</h4>
              </div>
            </article>
            <article className='skillsets__details'>
              <BsPatchCheckFill className="skillsets__details-icon" />
              <div>
                <h4>JavaScript</h4>
              </div>
            </article>
            <article className='skillsets__details'>
              <BsPatchCheckFill className="skillsets__details-icon" />
              <div>
                <h4>C#</h4>
              </div>
            </article>
            <article className='skillsets__details'>
              <BsPatchCheckFill className="skillsets__details-icon" />
              <div>
                <h4>React</h4>
              </div>
            </article>
            <article className='skillsets__details'>
              <BsPatchCheckFill className="skillsets__details-icon" />
              <div>
                <h4>Django</h4>
              </div>
            </article>
           </div>
        </div>
        {/* END OF LANGUAGES */}
        <div className="tools-and-libraries">
          <h3>Tools and Libraries</h3>
          <div className="skillsets__content">
            <article className='skillsets__details'>
              <BsPatchCheckFill className="skillsets__details-icon" />
              <div>
               <h4>Anaconda</h4>
              </div>
            </article>
            <article className='skillsets__details'>
              <BsPatchCheckFill className="skillsets__details-icon" />
              <div>
                <h4>Pandas/GeoPandas</h4>
              </div>
            </article>
            <article className='skillsets__details'>
              <BsPatchCheckFill className="skillsets__details-icon" />
              <div>
                <h4>OpenCV</h4>
              </div>
            </article>
            <article className='skillsets__details'>
              <BsPatchCheckFill className="skillsets__details-icon" />
              <div>
                <h4>Tensorflow</h4>
              </div>
            </article>
            <article className='skillsets__details'>
              <BsPatchCheckFill className="skillsets__details-icon" />
              <div>
                <h4>PyTorch</h4>
              </div>
            </article>
            <article className='skillsets__details'>
              <BsPatchCheckFill className="skillsets__details-icon" />
              <div>
                <h4>Doxygen</h4>
              </div>
            </article>
          </div>
        </div>
        {/* END OF TOOLS AND LIBRARIES*/}

        <div className="skills">
          <h3>Skills</h3>
          <div className="skillsets__content">
            <article className='skillsets__details'>
              <BsPatchCheckFill className="skillsets__details-icon" />
              <div>
               <h4>Computer Vision</h4>
              </div>
            </article>
            <article className='skillsets__details'>
              <BsPatchCheckFill className="skillsets__details-icon" />
              <div>
                <h4>Image Processing</h4>
              </div>
            </article>
            <article className='skillsets__details'>
              <BsPatchCheckFill className="skillsets__details-icon" />
              <div>
                <h4>Data Preprocessing</h4>
              </div>
            </article>
          </div>
        </div>

        <div className="working-knowledge">
          <h3>Working Knowledge</h3>
          <div className="skillsets__content">
            <article className='skillsets__details'>
              <BsPatchCheckFill className="skillsets__details-icon" />
              <div>
               <h4>GIS Programming</h4>
               {/* <small className='text-light'>Intermediate</small> */}
              </div>
            </article>
            <article className='skillsets__details'>
              <BsPatchCheckFill className="skillsets__details-icon" />
              <div>
               <h4>Azure</h4>
               {/* <small className='text-light'>Intermediate</small> */}
              </div>
            </article>
            <article className='skillsets__details'>
              <BsPatchCheckFill className="skillsets__details-icon" />
              <div>
               <h4>AWS</h4>
               {/* <small className='text-light'>Prior Experience</small> */}
              </div>
            </article>
            <article className='skillsets__details'>
              <BsPatchCheckFill className="skillsets__details-icon" />
              <div>
               <h4>Web Development</h4>
               {/* <small className='text-light'>Prior Experience</small> */}
              </div>
            </article>
          </div>
        </div>

       </div>
    </section>
  )
}

export default Skillsets