import React from 'react'
import './about.css'
import TREE from '../../assets/tree.png'

const About = () => {
  return (
    <section id="about">
        <h2>About Me</h2>

        <div className="container about__container">
          <div className="about__me">
            <div className="about__me-image">
                <img src={TREE} alt="About Image"></img>
            </div>
          </div>

          <div className="about__content">
             <p style={{"fontSize": "32px"}}>
              I am currently a computer vision software engineer at Aonic. Previously, I have worked with
              microcontrollers, websites, and desktop apps, and I am always eager to try out new technologies. 
              Currently seeking computer vision or full stack engineering opportunities.
             </p>

             <a href="#contact" className='btn btn-primary'>Let's Talk</a>
          </div>
        </div>
    </section>
  )
}

export default About